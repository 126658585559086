var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block block-rounded block-fx-pop"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.allLogs.data,
      "total": _vm.allLogs.total,
      "loading": _vm.loading.getAllLogs,
      "columns": _vm.columns,
      "page-size": 20,
      "fetch-items": _vm.getAllLogs,
      "default-sort-key": "createdAt",
      "default-sort-direction": -1
    },
    scopedSlots: _vm._u([{
      key: "createdAt",
      fn: function (slotProps) {
        return [_vm._v(" " + _vm._s(_vm._f("date")(slotProps.data.createdAt, 'DD/MM/YYYY HH:mm:ss')) + " ")];
      }
    }, {
      key: "userSub",
      fn: function (slotProps) {
        return [_vm._v(" " + _vm._s(_vm._f("user")(slotProps.data.userSub, _vm.users.data)) + " ")];
      }
    }, {
      key: "context-type",
      fn: function (slotProps) {
        return [_c('span', {
          staticClass: "badge badge-primary text-capitalize"
        }, [_vm._v(_vm._s(slotProps.data.context.type))])];
      }
    }, {
      key: "type",
      fn: function (slotProps) {
        return [_c('span', {
          staticClass: "badge text-capitalize"
        }, [_vm._v(_vm._s(_vm.handleType(slotProps.data.type)))])];
      }
    }, {
      key: "context",
      fn: function (slotProps) {
        return [_c('div', {
          attrs: {
            "id": `accordion_${slotProps.data._id}`
          }
        }, [_c('div', [_c('div', {
          attrs: {
            "id": `accordion_${slotProps.data._id}_h1`,
            "role": "tab"
          }
        }, [_c('a', {
          staticClass: "font-w600",
          attrs: {
            "data-toggle": "collapse",
            "data-parent": `#accordion_${slotProps.data._id}`,
            "href": `#accordion_${slotProps.data._id}_q1`
          }
        }, [_vm._v("More Details...")])]), _c('div', {
          staticClass: "collapse pt-3",
          attrs: {
            "id": `accordion_${slotProps.data._id}_q1`,
            "data-parent": `#accordion_${slotProps.data._id}`
          }
        }, [slotProps.data.context.idsDeleted ? _c('div', [_c('p', [_c('strong', [_vm._v("Associated Items " + _vm._s(slotProps.data.type === 'delete' ? 'Deleted' : 'Restored') + " ")])]), _c('ul', {
          staticClass: "list"
        }, _vm._l(slotProps.data.context.idsDeleted, function (ids, name) {
          return _c('li', {
            key: name
          }, [_c('div', {
            staticClass: "mt-2"
          }, [_c('strong', [_vm._v(_vm._s(_vm.handleDetailsTitle(ids, name)) + " ")])]), _vm._l(ids, function (id) {
            return _c('div', {
              key: id
            }, [_vm._v(" " + _vm._s(id) + " ")]);
          })], 2);
        }), 0)]) : _c('div', [_c('ul', {
          staticClass: "list-unstyled"
        }, _vm._l(Object.keys(slotProps.data.context).filter(function (k) {
          return k !== 'errors';
        }), function (key) {
          return _c('li', {
            key: key
          }, [_c('strong', [_vm._v(_vm._s(key) + ":")]), _vm._v(" " + _vm._s(slotProps.data.context[key]) + " ")]);
        }), 0), slotProps.data.context.errors && slotProps.data.context.errors.length > 0 ? _c('ul', {
          staticClass: "list-unstyled",
          staticStyle: {
            "width": "600px"
          }
        }, _vm._l(slotProps.data.context.errors, function (error, index) {
          return _c('li', {
            key: index,
            staticClass: "border-bottom py-2"
          }, [_vm._v(" " + _vm._s(error) + " ")]);
        }), 0) : _vm._e()])])])])];
      }
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }